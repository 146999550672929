<template>
  <v-container fluid class="pa-0 ma-0">

    <v-toolbar dense flat color="grey lighten-2" class="ma-0 pa-0">
      <div class="text-lg-left">
        <v-chip small color="primary">
          {{ litems.length }}
        </v-chip>
        <v-btn text icon
          @click="initVals"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
              >mdi-refresh</v-icon>
            </template>
            <span>초기화</span>
          </v-tooltip>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div style="width: 110px" class="mx-3 hidden-xs-only">
        <v-select
          v-model="search.sf"
          :items="select.sf"
          label="검색대상"
          item-text="text"
          item-value="value"
          dense
          class="mt-0"
          rounded
          flat
          hide-details
          solo-inverted
          style="font-size: 0.785rem !important"
        ></v-select>
      </div>
      <v-text-field
        v-model="search.sw"
        label="검색"
        append-icon="mdi-magnify"
        clearble
        class="hidden-xs-only"
        dense
        flat
        hide-details
        rounded
        solo-inverted
        @keyup.enter="searchPopBtn"
        @click:append="searchPopIcon"
      ></v-text-field>
      <!-- !! pdf 저장은 일단 주석처리 -->
      <!-- <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen"
      >
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn> -->
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="light-blue"
      ></v-progress-linear>
    </v-toolbar>
    <v-divider></v-divider>

    <v-card
      tile
      :elevation="0"
    >
      <v-row
        align="center"
        justify="center"
        no-gutters
        class="ma-0 pa-0"
      >
        <v-col cols="12">

          <!-- content area -->
          <v-row
            no-gutters
            justify="start"
            class="ma-0 pa-0"
          >
            <!-- 좌측 리스트 시작 -->
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? '12' : '6'"
              class="pa-1 ma-0"
            >
              <v-list
                v-if="litems.length > 0"
                dense
                rounded
              >
                <!-- <v-subheader>적용 항목</v-subheader> -->
                <v-list-item
                  v-for="(item, i) in litems"
                  :key="i"
                  @click.stop="viewArticle(item.id)"
                >
                  <v-list-item-content>
                    <v-hover v-slot:default="{ hover }">
                      <v-list-item-title
                        class="text-body-1 py-2"
                      >
                        <v-icon
                          class="mr-2"
                          :color="hover ? 'primary' : ''"
                        >
                          {{ hover ? 'mdi-record-circle' : 'mdi-record' }}
                        </v-icon>
                        {{ item.subject }}
                      </v-list-item-title>
                    </v-hover>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-row
                v-else
              >
                <v-col class="ma-1 pa-3">
                  <!-- <v-alert
                    dense
                    text
                    type="warning"
                  >
                    등록된 데이터가 없습니다.
                  </v-alert> -->
                </v-col>
              </v-row>
              <!-- sm 보다 작은 화면일때 칸띄우기 -->
              <v-row no-gutters>
                <v-col>
                  <div class="mb-8" style="min-height: 140px;">&nbsp;</div>
                </v-col>
              </v-row>
            </v-col>

            <!-- 가운데 -->
            <v-divider vertical></v-divider>

            <!-- 우측 리스트 -->
            <v-col
              class="pa-1 ma-0"
            >
              <v-row
                no-gutters
                justify="start"
                class="ma-0 pa-0"
              >
                <v-col cols="12">
                  <v-toolbar
                    dense
                    flat
                    class="pa-0 ma-0"
                  >
                    <v-icon color="warning" class="mr-1">mdi-assistant</v-icon>
                    <span class="text-h6 font-weight-bold">주요사항</span>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <!-- <v-divider></v-divider> -->

                  <!-- <div class="ma-3"></div> -->

                  <v-list
                    v-if="ritems.length > 0"
                    dense
                    rounded
                  >
                    <!-- <v-subheader>적용 항목</v-subheader> -->
                    <v-list-item
                      v-for="(item, i) in ritems"
                      :key="i"
                      @click.stop="viewArticle(item.id)"
                    >
                      <v-list-item-content>
                        <v-hover v-slot:default="{ hover }">
                          <v-list-item-title
                            class="text-body-1 py-2"
                          >
                            <v-icon
                              class="mr-2"
                              :color="hover ? 'warning' : ''"
                            >
                              {{ hover ? 'mdi-record-circle-outline' : 'mdi-record' }}
                            </v-icon>
                            {{ item.subject2 }}
                          </v-list-item-title>
                        </v-hover>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-row
                    v-else
                  >
                    <v-col class="ma-1 pa-3">
                      <!-- <v-alert
                        dense
                        text
                        type="warning"
                      >
                        등록된 데이터가 없습니다.
                      </v-alert> -->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--// content area -->

        </v-col>
      </v-row>
    </v-card>

  </v-container>
</template>

<script>
import sleep from '@/lib/sleep'

// @: filters
import cutString from '@/filters/cutString'

// 구분: 현재 디렉토리명
const currDir = 'guide'

export default {
  name: 'Guide',

  components: {
  },

  data: () => ({
    // 구분: 로딩 & 리프레시
    loading: false,
    refresh: false,
    // 구분: 꼼수 - 헤더스에 별로 의미없는 1개만 매칭시킨다.
    headers: [
      {
        text: '',
        value: 'id',
        align: 'start', // !! left 가 아니라 start?
        sortable: false,
        width: '100%'
      }
    ],
    // 구분: 리스트용 변수들
    litems: [], // 좌측 리스트 - 모든 이용가이드
    ritems: [], // 우측 리스트 - 중요사항
    // 구분: v-model 과 params 로 백앤드로 전송되는 객체
    params: { // 검색용 인자 객체
      draw: 0,
      where: {}
    },
    // 구분: 검색에 필요한 변수들 - 초기화 필요
    search: {
      sf: 1, // 검색어 검색 select 의 선택된 필드값
      sw: '' // 검색어 검색 text box input 값
    },
    // 구분: 셀렉트 객체들
    select: {
      sf: [ // 검색어 검색 필드 1 셀렉트
        { text: '제목', value: 1 },
        { text: '내용', value: 2 }
      ]
    },
    // 구분: 기타 변수들
    timeout: null // delay 시 필요
  }),

  watch: {
    // 주의:사용안함:[2021.10.1]자동으로 검색되지 않게 수정
    // 'search.sw': { // 검색어 자동 감지
    //   handler () {
    //     this.searchWordDelay()
    //   }
    // }
  },

  created () {
  },

  mounted () {
    this.initialize()
  },

  methods: {
    cutString,
    dummy () {
      console.log('dummy dummy ~ ')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message, color: 'error', timeout: 5000, shaped: true })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (const key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    initialize () {
      this.list()
    },
    // 참고: watch 로 검색시 약간의 딜레이를 줘야 한다.
    delay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.list()
      }, ms)
    },
    // 검색어 검색시 딜레이 주는 함수
    searchWordDelay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.searchWord()
      }, ms)
    },
    // 중요: 등록시 검색등에 쓰이는 변수 초기화하고 리스트 재패칭
    async initVals () {
      this.doInit().then(() => {
        this.delay(100)
      })
    },
    // 변수 초기화 실행
    doInit () {
      return new Promise((resolve, reject) => {
        // 구분: params 로 백앤드로 전송되는 값
        this.params.draw = 0
        this.params.where = {}

        // 구분: 검색용(v-model) 변수 - 초기값 설정이 필요
        this.search.sf = 1 // 검색어 검색 의 선택된 필드값
        this.search.sw = '' // 검색어 검색의 text box input 값

        // 구분: 기타 초기값 설정이 필요한 변수들
        this.litems = [] // 좌측 리스트 초기화
        this.ritems = [] // 우측 리스트 초기화

        this.loading = false

        resolve(true)
      })
    },
    // !! 리스트 패칭
    async list () {
      try {
        if (this.loading) return
        this.loading = true
        this.refresh = true

        this.params.draw++

        // !! 검색용 객체 만들기 - where 의 값이 없으면 삭제한다.
        const ws = this.params.where
        for (const key in ws) {
          if (!ws[key]) {
            delete ws[key]
          }
        }
        // console.log(this.params.where)

        // !! 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(500 - Math.floor(Math.random() * 300))

        const { data } = await this.$axios.get(`lawork/member/guideList`, { params: this.params })
        if (!data.success) throw new Error(`list error: ${data.message}`)

        // 참고: 데이터 반영
        this.litems = data.litems // 좌측 리스트 - 모든 이용가이드
        this.ritems = data.ritems // 우측 리스트 - 주요사항만

        this.loading = false
        this.refresh = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 상세페이지로 가기
    viewArticle (id) {
      this.$router.push(`/${currDir}/${id}`)
    },
    // 구분: -- 검색처리 메소드 모음
    // 팝업 검색 버튼 클릭 이벤트 핸들러
    async searchPopBtn () {
      try {
        // 검색어 검색 처리
        await this.searchWord()
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:[2021.10.1]팝업검색시 돋보기 아이콘을 클릭할때 검색처리 - 검색어가 없으면 아무것도 안함
    async searchPopIcon () {
      try {
        // 사용안함:검색어가 있는 경우만 searchPopBtn() 를 실행
        // if (this.search.sw) {
        //   await this.searchPopBtn()
        // }
        await this.searchPopBtn()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:[검색] - 검색어 검색 처리 함수
    async searchWord () {
      try {
        if (this.search.sw.length > 0) { // 검색어가 있으면 파라미터에 넣고
          this.params.where.sf = this.search.sf
          this.params.where.sw = this.search.sw
        } else { // 없어도 일단 넣지만 값을 비운다. list() 에서 알아서 삭제된다.
          this.params.where.sf = ''
          this.params.where.sw = ''
        }

        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
/* 2020.11.28 체크박스정렬이 깨져서 주석처리 */
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
} */
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  구분: vue2editor css
*/
.vue2editorViewer p {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  /* padding-top: 2px !important; */
  /* padding-bottom: 2px !important; */
}
</style>
